<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="align-start grey">
            <h4 class="text-bold">
              Prochaines activations
            </h4>
            <v-tooltip
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 bold"
                  icon
                  v-bind="attrs"
                  primary
                  outlined
                  x-small
                  v-on="on"
                >
                  <h2>?</h2>
                </v-btn>
              </template>
              <span>Activations dont la date d'échéance est de moins de cinq jours.</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-container>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="formatDecoderExpired"
                  :items-per-page="10"
                  :search="search"
                  item-class="font-weight-bold"
                  item-key="id"
                  class="mt-4"
                >
                  <!-- formule -->
                  <template #[`item.formula`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column">
                        <span class="d-block font-weight-semibold text--primary text-truncate">{{
                            nomFormule(item.formula)
                          }}</span>
                        <small> <span class="font-weight-semibold text--primary">Option :</span>
                          {{ nomOption(item.option) }}</small>
                      </div>
                    </div>
                  </template>

                  <template #[`item.montant`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column">
                        <span class="d-block font-weight-semibold text--primary text-truncate">{{
                            withCurrency(parseInt(prixFormule(item.formula)) + parseInt(prixOption(item.option)))
                          }}</span>
                      </div>
                    </div>
                  </template>

                  <!-- date debut -->
                  <template #[`item.start_subscription`]="{item}">
                    <div class="d-flex align-center">
                      {{
                         moment(item.start_subscription).format('D/MM/YYYY')
                      }}
                    </div>
                  </template>

                  <!-- date fin -->
                  <template #[`item.end_subscription`]="{item}">
                    <div class="d-flex align-center">
                      {{ moment(item.end_subscription).format('D/MM/YYYY') }}
                    </div>
                  </template>

                  <template #[`item.graphe`]="{item}">
                    <div class="circular">
                      <v-progress-circular
                        :value="item.pourcentage"
                        :color="item.chartColor"
                        :size="50"
                        :rotate="-90"
                      >
                        <span class="text-sm"><small>{{ item.reste }}</small></span>
                      </v-progress-circular>
                    </div>
                  </template>

                  <!-- status -->
                  <template #[`item.action`]="{item}">

                    <v-btn
                      v-if="item.is_auto===0"
                      color="info"
                      dark
                      small
                      outlined
                    >
                      <v-icon
                        dark
                        left
                      >
                        {{ icons.mdiReload }}
                      </v-icon>
                      Réabonner
                    </v-btn>
                    <span v-else
                          class="font-weight-semibold text-truncate success--text text-center">Renouvellement <br>automatique</span>
                  </template>
                </v-data-table>
              </v-container>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiReload,
  mdiArrowTopRight,
  mdiPlus,
  mdiCloseCircle,
  mdiPlusCircle,
} from '@mdi/js'
import {ref, computed, onBeforeMount} from '@vue/composition-api/dist/vue-composition-api'
import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'

import moment from 'moment'
import Http from '@/helpers/http'
import {withCurrency} from '@/helpers/constants/operation'

Vue.prototype.moment = moment

export default {
  components: {
    VueApexCharts,
  },
  setup() {
    const chartOptions = {
      chart: {
        type: 'radialBar',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '40%',
          },
          track: {
            background: '#ebe9f1',
          },
          dataLabels: {
            name: {
              offsetY: 26,
            },
            value: {
              fontSize: '1.125rem',

              // ! This isn't working???
              fontWeight: 600,
              offsetY: -14,
              formatter(value) {
                return `${value}k`
              },
            },
            total: {
              show: true,
              label: 'Jours restants',
              fontSize: '10px',
            },
          },
        },
      },

      grid: {},
    }

    const getChartConfig = value => {
      const options = JSON.parse(JSON.stringify(chartOptions))
      options.colors = ['#44b924']

      return options
    }

    const selected = []
    const usreList = ref([])

    const formule = ref([])
    const option = ref([])
    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))

    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = response.data
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const nomFormule = code => {
      // eslint-disable-next-line array-callback-return
      const name = formule.value.filter(item => item.code === code)
      if (name.length === 1) return name[0].label

      return code
    }

    const nomOption = code => {
      // eslint-disable-next-line array-callback-return,no-return-assign
      const name = option.value.filter(item => item.code === code)
      console.log(option.value)
      if (name.length === 1) return name[0].nom

      return code
    }

    const prixFormule = code => {
      // eslint-disable-next-line array-callback-return
      const name = formule.value.filter(item => item.code === code)
      if (name.length === 1) return name[0].pu

      return 0
    }

    const prixOption = code => {
      // eslint-disable-next-line array-callback-return,no-return-assign
      const name = option.value.filter(item => item.code === code)
      console.log(option.value)
      if (name.length === 1) return name[0].pu

      return 0
    }

    const today = moment(new Date()).format('YYYY-MM-DD')

    // eslint-disable-next-line consistent-return
    const lastDecoderExpired = () => {
      if (usreList.value.length) {
        // eslint-disable-next-line array-callback-return,consistent-return
        return usreList.value.slice().reverse().filter(item => {
          // return item
          const end = moment(item.end_subscription).format('YYYY-MM-DD')

          // if (moment(end).diff(today, 'days') >= 0 && moment(end).diff(today, 'days') <= 5) {
          if (moment(end).diff(today, 'days') >= 0 && moment(end).diff(today, 'days') <= 5) {
            return item
          }
        })

        return usreList.value
      }
    }

    const decodeurEcheanceList = ref([{}])

    const loadDecodeursAtEcheance = () => {
      Http.get(`get-decoders-echeance/${contrat.pivot.customer_segment_id}`)
        .then(response => {
          decodeurEcheanceList.value = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }

    const formatDecoderExpired = computed(() => {
      const decoders = lastDecoderExpired()
      if (decoders !== undefined) {
        decoders.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.resteO = moment(item.end_subscription).diff(today, 'days')
          // eslint-disable-next-line no-param-reassign
          item.reste = Math.abs(moment(today, 'YYYY-MM-DD').diff(item.end_subscription, 'days'))
          // eslint-disable-next-line no-param-reassign
          item.etat = item.resteO < 0 ? 'Echu' : 'Actif'
          // eslint-disable-next-line no-param-reassign
          item.start_subscription = item.start_subscription == null ? moment(item.end_subscription).subtract(30, "days").format('YYYY-MM-DD'):item.start_subscription
          item.total = moment(item.end_subscription).diff(item.start_subscription, 'days')
          // eslint-disable-next-line no-param-reassign
          item.pourcentage = item.etat === 'Actif' ? Math.round((item.reste / item.total) * 100) : 0
          // eslint-disable-next-line no-param-reassign
          item.chartColor = (item.resteO > 5) ? 'success' : 'error'
        })
      }

      return decoders
    })
    const search = ref('')
    const Decodeur = ref([{
      Numero: '',
      Emplacement: '',
      Formule: '',
      Option: '',
      DateDebut: '',
      Datefin: '',
      menu: '',
      menu2: '',
    }])

    onBeforeMount(() => {
      loadFormules()
      loadOptions()

      // loadDecodeursAtEcheance()
    })

    return {
      selected,
      headers: [
        {
          text: 'Emplacement', value: 'location', divider: false, align: 'center',
        },
        {
          text: 'N° Décodeur', value: 'number', divider: false, align: 'center',
        },
        {
          text: 'Offre', value: 'formula', divider: false, align: 'center',
        },
        {
          text: 'Montant', value: 'montant', divider: false, align: 'center',
        },
        {
          text: 'Début d\'abonnement',
          value: 'start_subscription',
          divider: false,
          align: 'center',
        },
        {
          text: 'Fin d\'abonnement',
          value: 'end_subscription',
          divider: false,
          align: 'center',
        },
        {
          text: 'Jours restants', value: 'graphe', divider: false, align: 'center',
        },

        // { text: 'Etat', value: 'is_auto', divider : true },
        { text: 'Action', value: 'action', divider: false, align: 'center' },
      ],
      usreList,
      status: {
        1: 'Actif',
        2: 'Echu',
      },
      search,
      chartOptions,
      getChartConfig,
      dialog: false,
      Decodeur,
      formule,
      option,
      loadFormules,
      loadOptions,
      nomFormule,
      nomOption,
      prixFormule,
      prixOption,
      lastDecoderExpired,
      formatDecoderExpired,
      loadDecodeursAtEcheance,
      decodeurEcheanceList,
      withCurrency,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiReload,
        mdiArrowTopRight,
        mdiPlus,
        mdiCloseCircle,
        mdiPlusCircle,
      },
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
  },
  created() {
    this.getDecodeurs()
  },
  methods: {
    getDecodeurs() {
      // const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
      // const role = JSON.parse(localStorage.getItem('defaultRole'))

      // const param = {
      // roleId: role.id,
      // contractId: contrat.id,
      // }

      // console.log(param)

      Http.get(`get-decoders/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(async response => {
          this.usreList = await response.data
          if (this.usreList.length) {
            for (let i = 0; i < this.usreList.length; i++) {
              this.usreList[i].reste = this.usreList[i].end_subscription < moment().format('YYYY-MM-DD') ? moment.utc(moment().diff(this.usreList[i].end_subscription)).format('DD') : moment.utc(moment(this.usreList[i].end_subscription).diff(moment())).format('DD')
              this.usreList[i].etat = this.usreList[i].end_subscription < moment().format('YYYY-MM-DD') ? 'Echu' : 'Actif'
              this.usreList[i].total = moment(this.usreList[i].end_subscription).diff(this.usreList[i].start_subscription, 'days')
              this.usreList[i].pourcentage = this.usreList[i].etat === 'Actif' ? Math.round((this.usreList[i].reste / this.usreList[i].total) * 100) : 0
              this.usreList[i].chartColor = this.usreList[i].etat === 'Actif' ? 'success' : 'error'
            }
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    },
  },
}
</script>
<style scoped>
.grey {
  background: #ded9d9 !important;
  padding: .5rem;
}

.blue {
  color: #2366ec;
}

fieldset {
  border-width: 1px !important;
  border-color: #ffffff;
}

.red {
  color: red;
}

.green {
  color: #44b924;
}

.circular {
  padding: 5px;
}
</style>
